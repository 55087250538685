import React, { useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap';
import config from "../../config";
import Footer from "../section/footer";
import MFAOption from "./mfa"
import { Login } from "@slbnsc/react-portal";
import RegisterForm from "../section/register";
import ForgotPassword from "../forgot-password/index";
import Terms from "../portal/terms";
import PrivacyPolicy from "../portal/privacy-policy";

const  LoginPage = (props) =>{
  const [register, setRegister] = useState(false);
  const [fullHeight, setFullHeight] = useState(true);
  const [showTerm, setTerm] = useState(false);
  const [showPolicy, setPolicy] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [mfa, setMfa] = useState(null);
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  return (
  <>
    {showTerm && <Terms onClose={() => setTerm(false)} />}
    {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
    <Container fluid className={`fh no-gutters login-main-body login-page ${fullHeight ? "vh-page" : ""} `}>
      <Row className="login-main-header">
        <header className="login-main-logo">
          <a href="https://www.floharrislodge.org.au/" target="_blank">
            <img src="https://cdn.vostro.app/cdn/hotspots/flo-harris/fhl_logo_long_light_optimised.png" className="image-brand-logo" alt="brand-logo"/>
          </a>
        </header>
      </Row>
      <Row className="fh-element align-items-center">
        <Col>
          {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container>
            <Row>
              {!mfa && ( <Col lg={6} xl={5} className="login-form-container">
                <div className="login-section-container">
                  <div className="login-container">
                    <p className="login-title">
                      {"Login to your account"}
                    </p>
                  
                    <Login
                      inputClassName={"login-form-input"}
                      noLabels={false}
                      labelClassName={"form-input-label"}
                      authUrl={config.auth} 
                      portalUrl={config.portal} 
                      apiUrl={config.backend}
                      noPlaceHolders={true}
                      withErrorOnButton={true}
                      floating={true}
                      inline={true}
                      withUserIcon={true}
                      loginBtnLabel={"Sign In"}
                      withPasswordIcon={true}
                      withRecoverPasswordLink={true}
                      setMfa={setMfa} 
                      recoverPasswordLink={<>
                        <a className="forgot-password" onClick={handleClickForgotPassword}>{"Recover Password"}</a>
                      </>}
                    />
                  </div>
                </div>
              </Col>)}
              <Modal
                show={mfa}
                className="login-mfa-panel-modal"
                onHide={()=> {}}
              >
                <MFAOption mfa={mfa} setMfa={setMfa} authUrl={config.auth} portalUrl={config.portal} apiUrl={config.backend}/>
              </Modal>
              <Col lg={6} xl={7} className="mb-5 mb-lg-0 login-brand-container">
                <div className="login-brand-primary-section">
                  <a className="login-brand-logo-link" href="https://www.floharrislodge.org.au/" target="_blank"> 
                    <img 
                      alt="login-brand-logo" 
                      className="login-brand-logo"
                      src="https://cdn.vostro.app/cdn/hotspots/flo-harris/fhl_logo_long_light_optimised.png"
                    />
                  </a>
                 
                  <p className="login-brand-heading">
                    {"Provides a caring community"} <br/>
                    {"for young people."}
                  </p>    
                </div>
             
                <div className="login-brand-secondary-section">
                  <img 
                    alt="login-brand-image" 
                    className="login-brand-image"
                    src="https://cdn.vostro.app/cdn/hotspots/flo-harris/lunchtime flo harris.png"
                  />
                </div>

                <div className="login-brand-footer-section">
                  <Row className="footer-row">
                    <Col xs="auto">
                      <a href="/">
                        {`© ${year} Flo Harris Lodge. `}
                      </a>
                      {`All rights reserved. | `}
                      <a onClick={() => setTerm(true)}>
                        {"Terms And Conditions"}
                      </a>
                      <span> | </span>
                      <a href='/esafety' target='_blank' className='esafety-link'>
                        {"eSafety"}
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer year={year} setTerm={setTerm}/>
        </Col>
      </Row>
    </Container>
  </>
  );
}


export default LoginPage;

